import React, { useState } from "react"

import styled from "styled-components"

function CopyIcon(props) {
  return (
    <svg
      height="1em"
      viewBox="-40 0 512 512"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="white"
        d="M271 512H80c-44.113 0-80-35.887-80-80V161c0-44.113 35.887-80 80-80h191c44.113 0 80 35.887 80 80v271c0 44.113-35.887 80-80 80zM80 121c-22.055 0-40 17.945-40 40v271c0 22.055 17.945 40 40 40h191c22.055 0 40-17.945 40-40V161c0-22.055-17.945-40-40-40zm351 261V80c0-44.113-35.887-80-80-80H129c-11.047 0-20 8.953-20 20s8.953 20 20 20h222c22.055 0 40 17.945 40 40v302c0 11.047 8.953 20 20 20s20-8.953 20-20zm0 0"
      />
    </svg>
  )
}

const Wrapper = styled.div`
  margin-top: 5%;
  margin-left: 5%;
`

const Item = styled.div`
  font-size: ${props => props.size}px;
  margin-top: 10px;
`

const Footer = styled.div`
  width: 100%;
  padding: 24px;
  position: absolute;
  bottom: 0;
  background-color: #283647;
  display: flex;
`

const Email = styled.a`
  color: #ffffff;
  margin-left: 4px;
`

export default function Home() {
  const [copy, setcopy] = useState(0)
  return (
    <>
      <Wrapper>
        {[64, 56, 48, 40, 32, 26, 18, 10].map(n => (
          <Item key={`jey-${n}`} size={n}>
            Jey!
          </Item>
        ))}
      </Wrapper>
      <Footer>
        Contact us:
        <Email href="mailto:hi@jey.gmbh">hi@jey.gmbh</Email>
        <a
          role="button"
          aria-hidden="true"
          onAnimationEnd={() => setcopy(0)}
          style={{ marginLeft: 10 }}
          onClick={() => {
            setcopy(1)
            navigator.clipboard.writeText("hi@jey.gmbh")
          }}
          clicked={copy}
        >
          <CopyIcon />
        </a>
      </Footer>
    </>
  )
}
